import { ReactNode, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BankCode, BankName } from '@libs/backend-common';
import { screenSizes } from '@libs/theme';
import { WithdrawalMethods } from '@libs/types';
import {
  formatAmountWithSymbol,
  getEstimatedAmount,
  getUsdOrEurCurrencySymbol,
} from '@libs/helpers';

import Typography from '../typography/Typography';
import TableCell from '../table/TableCell';
import { InfoPaths, FeesTablesSelectorId } from '../constants';
import { StateSetter } from '../types';

import {
  CustomMinMaxText,
  DestinationBankName,
  IconWrapper,
  MinMaxText,
  StyledTableCell,
  TextWrapperDesktop,
  TextWrapperDesktopBreakSpace,
  TextWrapperMobile,
} from './styles';

import {
  minFoundingOptwithCard,
  minFoundingOptWithBankWireGel,
  minFoundingOptWithBankWireUsd,
  minFoundingOptWithCardWithdraw,
  minFoundingOptWithBankwireWithdrawGel,
  minFoundingOptWithBankwireWithdrawUsd,
  minFoundingOptWithBankwireWithdrawTbcGelWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee,
  minFundingOptionCardOtherBank,
  minFundingOptionsWithBankWireOtherBankGel,
  minFundingOptionsWithBankWireOtherBankGelLarge,
  minFoundingOptWithBankwireWithdrawEur,
} from './constants';

type TablesData = {
  internalTransferData: Record<string, ReactNode>[];
  cardWithdrawalData: Record<string, ReactNode>[];
  bankWireWithdrawalData: Record<string, ReactNode>[];
  cryptoWithdrawalData: Record<string, ReactNode>[];
};

export const useLandingFeesTable = (landingFeesTableProps: {
  anyGeorgianBank: string;
  currencyGelOrEur: string;
  currencyUsd: string;
  processingTime: string;
  commonInstant: string;
  commonAny: string;
  commonCard: string;
  currencyGelUsd: string;
  commonBankwireBogOrSepa: string;
  commonBankwireOther: string;
  commonCurrency: string;
  commonFee: string;
  tableProccesing: string;
  commonLimit: string;
  walletActionDep: string;
  withdrawMeth: string;
  commonBankwireTbc: string;
  commonShortAny: string;
  commonMeth: string;
  commonFeeShort: string;
  tableAssets: string;
  tableAmount: string;
  tableAmountShort: string;
  tableBitnetFee: string;
  tablePrice: string;
  tableBlockchainFee: string;
  tableDestinationBank: string;
  landingFeesFundingOptions: string;
  landingFeesFundingDisclaimer: string;
  landingFeesCryptoWithdrawalFees: string;
  landingFeesInternalCryptoTransferFee: string;
  landingFeesWithdrawalMethods: string;
  landingFeesWithdrawalMethodsDisclaimer: string;
  landingFeesWireTransferWithdrawalFees: string;
  landingFeesCardWithdrawalFees: string;
  landingFeesAmount: string;
  landingFeesFixedAmount: string;
  landingFeesCryptoWithdrawalMethodsDisclaimer: string;
  withdrawalFeesRes: any;
  width: any;
  shouldRender: boolean;
  isJurisdictionGe: boolean;
  BankIcon: any;
  CardIcon: any;
  UsdtEtheriumImg: any;
  UsdtTronImg: any;

  commonUnlimited: string;
  commonUnlimitedShort: string;
  walletActionDeposit: string;
  commonWithdrawMethods: string;
  withdrawCryptoFee: string;
  convertFee: string;
  cryptoWithdrawSearchValue: any;
  setCryptoWithdrawSearchValue: any;
  filteredSupportedAssets: any;
  fullyLoaded: boolean;
  setFullyLoaded: StateSetter<boolean>;
}) => {
  const { colors } = useTheme();
  const mobileScreen = landingFeesTableProps.width < screenSizes.tablet;

  const textType =
    landingFeesTableProps.width <= screenSizes.tablet
      ? 'caption'
      : 'bodyButton';

  const formatBankDestination = useCallback(
    (bankName?: string) => {
      if (bankName) {
        return BankName[bankName as BankCode];
      } else {
        return landingFeesTableProps.anyGeorgianBank;
      }
    },
    [landingFeesTableProps.anyGeorgianBank]
  );

  const gelOrEurCurrency = ' ' + landingFeesTableProps.currencyGelOrEur;
  const usdCurrency = ' ' + landingFeesTableProps.currencyUsd;

  const titleType =
    landingFeesTableProps.width < screenSizes.tablet
      ? 'primaryMedium'
      : 'primaryBold';

  const fundingOptionsData = useMemo(
    () => {
      return [
        ...(landingFeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <TableCell
                    title={landingFeesTableProps.commonCard}
                    img={
                      <IconWrapper>
                        <landingFeesTableProps.CardIcon
                          color={colors.interactive.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    {landingFeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    <div>{minFoundingOptwithCard + '%'}</div>
                  </MinMaxText>
                ),
                processing: landingFeesTableProps.commonInstant,
                limit: landingFeesTableProps.commonUnlimited,
              },
            ]
          : []),
        {
          type: (
            <StyledTableCell
              title={landingFeesTableProps.commonBankwireBogOrSepa}
              img={
                <IconWrapper>
                  <landingFeesTableProps.BankIcon
                    color={colors.interactive.brandColor}
                  />
                </IconWrapper>
              }
              titleType={titleType}
              titleSize="caption"
            />
          ),
          currency: (
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              {landingFeesTableProps.currencyGelUsd}
            </Typography>
          ),
          fee: landingFeesTableProps.isJurisdictionGe ? (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireGel + gelOrEurCurrency + ' /'}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {gelOrEurCurrency + minFoundingOptWithBankWireGel}
              </TextWrapperMobile>
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireUsd + usdCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {usdCurrency + minFoundingOptWithBankWireUsd}
              </TextWrapperMobile>
            </MinMaxText>
          ) : (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireUsd + gelOrEurCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {minFoundingOptWithBankWireUsd + gelOrEurCurrency}
              </TextWrapperMobile>
            </MinMaxText>
          ),
          processing: landingFeesTableProps.processingTime,
          limit: mobileScreen
            ? landingFeesTableProps.commonUnlimitedShort
            : landingFeesTableProps.commonUnlimited,
        },
        ...(landingFeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <StyledTableCell
                    title={landingFeesTableProps.commonBankwireOther}
                    img={
                      <IconWrapper>
                        <landingFeesTableProps.BankIcon
                          color={colors.interactive.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    {landingFeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    <TextWrapperDesktopBreakSpace>
                      {minFundingOptionCardOtherBank + gelOrEurCurrency}
                    </TextWrapperDesktopBreakSpace>

                    <TextWrapperMobile>
                      {minFoundingOptWithBankWireGel + gelOrEurCurrency}
                    </TextWrapperMobile>
                  </MinMaxText>
                ),
                processing: landingFeesTableProps.commonInstant,
                limit: mobileScreen
                  ? landingFeesTableProps.commonUnlimitedShort
                  : landingFeesTableProps.commonUnlimited,
              },
            ]
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      landingFeesTableProps.commonCard,
      landingFeesTableProps.currencyGelOrEur,
      landingFeesTableProps.commonBankwireOther,
      landingFeesTableProps.currencyGelUsd,
      landingFeesTableProps.commonBankwireBogOrSepa,
    ]
  );

  const mobileTables = useMemo(() => {
    const depositCardOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: minFoundingOptwithCard + '%',
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const depositBogBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelUsd,
      },
      {
        title: landingFeesTableProps.commonFee,
        description:
          minFoundingOptWithBankWireGel +
          landingFeesTableProps.currencyGelOrEur +
          ' /' +
          minFoundingOptWithBankWireUsd +
          landingFeesTableProps.currencyUsd,
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const depositOtherBankBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: minFundingOptionCardOtherBank,
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawCardOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: minFoundingOptWithCardWithdraw + '%',
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawBogBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelUsd,
      },
      {
        title: landingFeesTableProps.commonFee,
        description:
          minFoundingOptWithBankWireGel +
          landingFeesTableProps.currencyGelOrEur +
          ' /' +
          minFoundingOptWithBankwireWithdrawUsd +
          landingFeesTableProps.currencyUsd,
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawOtherBankBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: (
          <CustomMinMaxText
            fontFamily="primaryBold"
            color={colors.text.default}
            type="caption"
          >
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee +
                gelOrEurCurrency}
            </div>
          </CustomMinMaxText>
        ),
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    return [
      ...(landingFeesTableProps.isJurisdictionGe
        ? [
            {
              title: landingFeesTableProps.walletActionDep,
              options: depositCardOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonCard}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.CardIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: depositBogBankWireOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonBankwireBogOrSepa}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.BankIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: depositOtherBankBankWireOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonBankwireOther}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.BankIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              title: landingFeesTableProps.withdrawMeth,
              options: withdrawCardOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonCard}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.CardIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: withdrawBogBankWireOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonBankwireBogOrSepa}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.BankIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: withdrawOtherBankBankWireOptions,
              insideTitle: (
                <TableCell
                  title={landingFeesTableProps.commonBankwireOther}
                  img={
                    <IconWrapper>
                      <landingFeesTableProps.BankIcon
                        color={colors.interactive.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
          ]
        : []),
    ];
  }, [
    landingFeesTableProps,
    colors.text.default,
    colors.interactive.brandColor,
    gelOrEurCurrency,
    titleType,
  ]);

  const withdrawalOptionsData = useMemo(
    () => {
      return [
        ...(landingFeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <TableCell
                    title={landingFeesTableProps.commonCard}
                    img={
                      <IconWrapper>
                        <landingFeesTableProps.CardIcon
                          color={colors.interactive.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    {landingFeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    <div>{minFoundingOptWithCardWithdraw + '%'}</div>
                  </MinMaxText>
                ),
                processing: landingFeesTableProps.commonInstant,
                limit: landingFeesTableProps.commonUnlimited,
              },
            ]
          : []),
        {
          type: (
            <StyledTableCell
              title={landingFeesTableProps.commonBankwireBogOrSepa}
              img={
                <IconWrapper>
                  <landingFeesTableProps.BankIcon
                    color={colors.interactive.brandColor}
                  />
                </IconWrapper>
              }
              titleType={titleType}
              titleSize="caption"
            />
          ),
          currency: (
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              {landingFeesTableProps.currencyGelUsd}
            </Typography>
          ),
          fee: landingFeesTableProps.isJurisdictionGe ? (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankwireWithdrawGel + gelOrEurCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {gelOrEurCurrency + minFoundingOptWithBankwireWithdrawGel}
              </TextWrapperMobile>
              <TextWrapperDesktop>
                {minFoundingOptWithBankwireWithdrawUsd + usdCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {usdCurrency + minFoundingOptWithBankwireWithdrawUsd}
              </TextWrapperMobile>
            </MinMaxText>
          ) : (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={colors.text.default}
            >
              {minFoundingOptWithBankwireWithdrawEur + '%'}
            </MinMaxText>
          ),
          processing: landingFeesTableProps.processingTime,
          limit: mobileScreen
            ? landingFeesTableProps.commonUnlimitedShort
            : landingFeesTableProps.commonUnlimited,
        },

        ...(landingFeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <StyledTableCell
                    title={landingFeesTableProps.commonBankwireOther}
                    img={
                      <IconWrapper>
                        <landingFeesTableProps.BankIcon
                          color={colors.interactive.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.default}
                  >
                    {landingFeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <>
                    <CustomMinMaxText
                      type={textType}
                      fontFamily="primaryMedium"
                      color={colors.text.default}
                    >
                      <TextWrapperDesktop>
                        {'0 - ' +
                          minFoundingOptWithBankwireWithdrawTbcGelWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelWithFee}
                      </TextWrapperMobile>
                      <TextWrapperDesktop>
                        {minFundingOptionsWithBankWireOtherBankGel +
                          minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee}
                      </TextWrapperMobile>
                      <TextWrapperDesktop>
                        {minFundingOptionsWithBankWireOtherBankGelLarge +
                          minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee}
                      </TextWrapperMobile>
                    </CustomMinMaxText>
                  </>
                ),
                processing: landingFeesTableProps.commonInstant,
                limit: mobileScreen
                  ? landingFeesTableProps.commonUnlimitedShort
                  : landingFeesTableProps.commonUnlimited,
              },
            ]
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      landingFeesTableProps.commonCard,
      landingFeesTableProps.currencyGelOrEur,
      landingFeesTableProps.commonBankwireTbc,
      landingFeesTableProps.currencyGelUsd,
      landingFeesTableProps.commonBankwireBogOrSepa,
      titleType,
    ]
  );

  const {
    internalTransferData,
    cardWithdrawalData,
    bankWireWithdrawalData,
    cryptoWithdrawalData,
  } = useMemo(() => {
    if (!landingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees)
      return {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      };

    const Amount = (
      <Typography
        type={textType}
        fontFamily="primaryMedium"
        color={colors.text.default}
      >
        {landingFeesTableProps.commonUnlimited}
      </Typography>
    );

    return landingFeesTableProps.filteredSupportedAssets.reduce(
      (acc: any, fee: any) => {
        const amount = Number(fee.fixed).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });

        const Asset = (
          <TableCell
            title={fee.assetName}
            description={fee.assetCode}
            img={fee.iconUrl}
          />
        );

        const Fee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {amount}
          </Typography>
        );

        const BlockChainFee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {getEstimatedAmount(fee.blockchainFee || 0)}
          </Typography>
        );

        const Price = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatAmountWithSymbol({
              amount: fee.price,
              symbol: getUsdOrEurCurrencySymbol(),
              shouldHaveSpace: false,
            })}
          </Typography>
        );

        const Destination = (
          <DestinationBankName
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatBankDestination(fee.extraConditions?.destinationBankCode)}
          </DestinationBankName>
        );
        if (fee.isCrypto && fee.method === WithdrawalMethods.BITNET_INTERNAL) {
          acc.internalTransferData.push({
            asset: (
              <TableCell
                title={fee.assetName.split(' ').splice(0, 2).join(' ')}
                description={fee.assetCode}
                img={fee.iconUrl}
              />
            ),
            fee: Fee,
            amount: Amount,
          });
        }

        if (fee.isCrypto && fee.method === WithdrawalMethods.FIREBLOCKS) {
          let assetImg: JSX.Element | string = fee.iconUrl;
          let assetCode: string = fee.assetCode;

          if (fee.assetCode === 'USDT') {
            if (fee.extraConditions?.destinationNetwork === 1) {
              assetImg = landingFeesTableProps.UsdtTronImg;
              assetCode = fee.assetCode + ' (TRC-20)';
            } else {
              assetImg = landingFeesTableProps.UsdtEtheriumImg;
              assetCode = fee.assetCode + ' (ERC-20)';
            }
          }

          acc.cryptoWithdrawalData.push({
            asset: (
              <TableCell
                title={fee.assetName.split(' ').splice(0, 2).join(' ')}
                description={
                  mobileScreen
                    ? ` ${formatAmountWithSymbol({
                        amount: fee.price,
                        symbol: getUsdOrEurCurrencySymbol(),
                      })}`
                    : assetCode
                }
                img={assetImg}
              />
            ),
            fee: Fee,
            blockchainFee: BlockChainFee,
            amount: Amount,
            price: Price,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_WIRE) {
          acc.bankWireWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_CARD) {
          acc.cardWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }
        return acc;
      },
      {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      } as TablesData
    );
  }, [
    landingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees,
    landingFeesTableProps.commonUnlimited,
    landingFeesTableProps.filteredSupportedAssets,
    landingFeesTableProps.UsdtTronImg,
    landingFeesTableProps.UsdtEtheriumImg,
    textType,
    colors.text.default,
    formatBankDestination,
    mobileScreen,
  ]);

  const tables = useMemo(() => {
    if (!landingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees) {
      return [];
    }

    const methodColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonMeth}
          </Typography>
        ),
        accessor: 'type',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonCurrency}
          </Typography>
        ),
        accessor: 'currency',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily="primaryBold">
                {landingFeesTableProps.commonFee}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily="primaryBold">
                {landingFeesTableProps.commonFeeShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.tableProccesing}
          </Typography>
        ),
        accessor: 'processing',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonLimit}
          </Typography>
        ),
        accessor: 'limit',
      },
    ];

    const withdrawalColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonMeth}
          </Typography>
        ),
        accessor: 'type',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonCurrency}
          </Typography>
        ),
        accessor: 'currency',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonFee}
          </Typography>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.tableProccesing}
          </Typography>
        ),
        accessor: 'processing',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {landingFeesTableProps.commonLimit}
          </Typography>
        ),
        accessor: 'limit',
      },
    ];

    const cryptoColumns = [
      {
        Header: landingFeesTableProps.tableAssets,
        accessor: 'asset',
      },
      {
        Header: landingFeesTableProps.commonFee,
        accessor: 'fee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              {landingFeesTableProps.tableAmount}
            </TextWrapperDesktop>
            <TextWrapperMobile>
              {landingFeesTableProps.tableAmountShort}
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];
    const cryptoWithdrawlFeeColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableAssets}
          </Typography>
        ),
        accessor: 'asset',
      },
      ...(!mobileScreen
        ? [
            {
              Header: (
                <Typography type="caption" fontFamily={titleType}>
                  {landingFeesTableProps.tablePrice}
                </Typography>
              ),
              accessor: 'price',
            },
          ]
        : []),
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableBitnetFee}
          </Typography>
        ),
        accessor: 'fee',
      },

      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableBlockchainFee}
          </Typography>
        ),
        accessor: 'blockchainFee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily={titleType}>
                {landingFeesTableProps.tableAmount}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily={titleType}>
                {landingFeesTableProps.tableAmountShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];

    const fiatColumns = [...cryptoColumns];
    fiatColumns.push({
      Header: landingFeesTableProps.tableDestinationBank,
      accessor: 'destination',
    });
    if (landingFeesTableProps.shouldRender) {
      return [
        {
          title: landingFeesTableProps.landingFeesFundingOptions,
          columns: methodColumns,
          data: fundingOptionsData,
          disclaimerText: landingFeesTableProps.landingFeesFundingDisclaimer,
          selectorId: '',
          searchId: '' as FeesTablesSelectorId,
        },
        {
          title: landingFeesTableProps.landingFeesWithdrawalMethods,
          columns: withdrawalColumns,
          selectorId: 'withdraw-methods-second',
          searchId: '' as FeesTablesSelectorId,
          data: withdrawalOptionsData,
          disclaimerText:
            landingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
        },
        {
          title: landingFeesTableProps.landingFeesCryptoWithdrawalFees,
          columns: cryptoWithdrawlFeeColumns,
          data: cryptoWithdrawalData,
          selectorId: 'withdraw-crypto-fee-second',
          searchId: '' as FeesTablesSelectorId,
          disclaimerText:
            landingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
        },

        // {
        //   title: landingFeesInternalCryptoTransferFee,
        //   columns: cryptoColumns,
        //   data: internalTransferData,
        //   selectorId: '',
        //   searchId: '' as FeesTablesSelectorId,
        // },
      ];
    }

    return [
      ...(!mobileScreen
        ? [
            {
              title: landingFeesTableProps.walletActionDep,
              columns: methodColumns,
              data: fundingOptionsData,
              selectorId: 'deposit-second',
              searchId: '' as FeesTablesSelectorId,
              disclaimerText:
                landingFeesTableProps.landingFeesFundingDisclaimer,
            },
            {
              title: landingFeesTableProps.landingFeesWithdrawalMethods,
              columns: withdrawalColumns,
              selectorId: 'withdraw-methods-second',
              searchId: '' as FeesTablesSelectorId,
              data: withdrawalOptionsData,
              disclaimerText:
                landingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
            },
          ]
        : []),
      // {
      //   title: landingFeesWireTransferWithdrawalFees,
      //   columns: fiatColumns,
      //   data: bankWireWithdrawalData,
      //   selectorId: '',
      // },
      // {
      //   title: landingFeesCardWithdrawalFees,
      //   columns: fiatColumns,
      //   data: cardWithdrawalData,
      //   selectorId: '',
      // },
      {
        title: landingFeesTableProps.landingFeesCryptoWithdrawalFees,
        columns: cryptoWithdrawlFeeColumns,
        data: cryptoWithdrawalData,
        selectorId: 'withdraw-crypto-fee-second',
        searchId: FeesTablesSelectorId.TABLE,
        disclaimerText:
          landingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
      },
      // {
      //   title: landingFeesInternalCryptoTransferFee,
      //   columns: cryptoColumns,
      //   data: internalTransferData,
      //   selectorId: 'convert-fee-second',
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    internalTransferData,
    cardWithdrawalData,
    bankWireWithdrawalData,
    cryptoWithdrawalData,
    landingFeesTableProps.shouldRender,
    landingFeesTableProps.landingFeesFundingOptions,
    landingFeesTableProps.landingFeesWithdrawalMethods,
  ]);

  const tablesForMobileScreen = useMemo(() => {
    const depositCardOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: minFoundingOptwithCard + '%',
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const bankWireFeeDescForGe =
      minFoundingOptWithBankWireGel +
      landingFeesTableProps.currencyGelOrEur +
      ' /' +
      minFoundingOptWithBankWireUsd +
      landingFeesTableProps.currencyUsd;

    const bankWireFeeDescForEu =
      minFoundingOptWithBankWireGel + landingFeesTableProps.currencyGelOrEur;

    const depositBogBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelUsd,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: landingFeesTableProps.isJurisdictionGe
          ? bankWireFeeDescForGe
          : bankWireFeeDescForEu,
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const depositOtherBankBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: landingFeesTableProps.isJurisdictionGe
          ? minFundingOptionCardOtherBank
          : minFoundingOptWithBankWireGel +
            landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawCardOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: minFoundingOptWithCardWithdraw + '%',
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawBogBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelUsd,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: landingFeesTableProps.isJurisdictionGe
          ? minFoundingOptWithBankWireGel +
            gelOrEurCurrency +
            ' /' +
            minFoundingOptWithBankwireWithdrawUsd +
            usdCurrency
          : minFoundingOptWithBankwireWithdrawEur + '%',
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const withdrawOtherBankBankWireOptions = [
      {
        title: landingFeesTableProps.commonCurrency,
        description: landingFeesTableProps.currencyGelOrEur,
      },
      {
        title: landingFeesTableProps.commonFee,
        description: landingFeesTableProps.isJurisdictionGe ? (
          <CustomMinMaxText
            fontFamily="primaryBold"
            color={colors.text.default}
            type="caption"
          >
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {minFundingOptionsWithBankWireOtherBankGel +
                minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {minFundingOptionsWithBankWireOtherBankGelLarge +
                minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee +
                gelOrEurCurrency}
            </div>
          </CustomMinMaxText>
        ) : (
          minFoundingOptWithBankWireGel + gelOrEurCurrency
        ),
      },
      {
        title: landingFeesTableProps.tableProccesing,
        description: landingFeesTableProps.commonInstant,
      },
      {
        title: landingFeesTableProps.commonLimit,
        description: landingFeesTableProps.commonUnlimited,
      },
    ];

    const cryptoWithdrawlFeeColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableAssets}
          </Typography>
        ),
        accessor: 'asset',
      },
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableBitnetFee}
          </Typography>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {landingFeesTableProps.tableBlockchainFee}
          </Typography>
        ),
        accessor: 'blockchainFee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily={titleType}>
                {landingFeesTableProps.tableAmount}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily={titleType}>
                {landingFeesTableProps.tableAmountShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];

    return [
      {
        id: InfoPaths.DEPOSIT,
        title: landingFeesTableProps.isJurisdictionGe
          ? landingFeesTableProps.walletActionDeposit
          : landingFeesTableProps.landingFeesFundingOptions,
        content: [
          ...(landingFeesTableProps.isJurisdictionGe
            ? [
                {
                  title: landingFeesTableProps.walletActionDep,
                  options: depositCardOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  insideTitle: (
                    <TableCell
                      title={landingFeesTableProps.commonCard}
                      img={
                        <IconWrapper>
                          <landingFeesTableProps.CardIcon
                            color={colors.interactive.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
          {
            options: depositBogBankWireOptions,
            selectorId: FeesTablesSelectorId.OPTION_LIST,
            title: landingFeesTableProps.shouldRender
              ? landingFeesTableProps.landingFeesFundingOptions
              : '',
            insideTitle: (
              <TableCell
                title={landingFeesTableProps.commonBankwireBogOrSepa}
                img={
                  <IconWrapper>
                    <landingFeesTableProps.BankIcon
                      color={colors.interactive.brandColor}
                    />
                  </IconWrapper>
                }
                titleType={titleType}
                titleSize="caption"
              />
            ),
          },

          ...(landingFeesTableProps.isJurisdictionGe
            ? [
                {
                  options: depositOtherBankBankWireOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  disclaimerText:
                    landingFeesTableProps.landingFeesFundingDisclaimer,
                  insideTitle: (
                    <TableCell
                      title={landingFeesTableProps.commonBankwireOther}
                      img={
                        <IconWrapper>
                          <landingFeesTableProps.BankIcon
                            color={colors.interactive.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        id: InfoPaths.WITHDRAW_METHODS,
        title: landingFeesTableProps.commonWithdrawMethods,
        content: [
          ...(landingFeesTableProps.isJurisdictionGe
            ? [
                {
                  title: landingFeesTableProps.withdrawMeth,
                  options: withdrawCardOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  insideTitle: (
                    <TableCell
                      title={landingFeesTableProps.commonCard}
                      img={
                        <IconWrapper>
                          <landingFeesTableProps.CardIcon
                            color={colors.interactive.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
          {
            options: withdrawBogBankWireOptions,
            selectorId: FeesTablesSelectorId.OPTION_LIST,
            title: landingFeesTableProps.shouldRender
              ? landingFeesTableProps.landingFeesWithdrawalMethods
              : '',
            insideTitle: (
              <TableCell
                title={landingFeesTableProps.commonBankwireBogOrSepa}
                img={
                  <IconWrapper>
                    <landingFeesTableProps.BankIcon
                      color={colors.interactive.brandColor}
                    />
                  </IconWrapper>
                }
                titleType={titleType}
                titleSize="caption"
              />
            ),
          },
          ...(landingFeesTableProps.isJurisdictionGe
            ? [
                {
                  options: withdrawOtherBankBankWireOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  disclaimerText:
                    landingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
                  insideTitle: (
                    <TableCell
                      title={landingFeesTableProps.commonBankwireOther}
                      img={
                        <IconWrapper>
                          <landingFeesTableProps.BankIcon
                            color={colors.interactive.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        id: InfoPaths.WITHDRAW_CRYPTO_FEE,
        title: landingFeesTableProps.withdrawCryptoFee,
        content: [
          {
            title: landingFeesTableProps.landingFeesCryptoWithdrawalFees,
            columns: cryptoWithdrawlFeeColumns,
            data: cryptoWithdrawalData,
            selectorId: FeesTablesSelectorId.TABLE,
            disclaimerText:
              landingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
          },
        ],
      },
      {
        id: InfoPaths.CONVERT_FEE,
        title: landingFeesTableProps.convertFee,
        content: [
          {
            selectorId: FeesTablesSelectorId.EXCHANGE_FEES_TABLE,
          },
        ],
      },
    ];
  }, [
    landingFeesTableProps,
    gelOrEurCurrency,
    usdCurrency,
    colors.text.default,
    colors.interactive.brandColor,
    titleType,
    cryptoWithdrawalData,
  ]);

  return {
    tables,
    mobileTables,
    tablesForMobileScreen,
    cryptoWithdrawSearchValue: landingFeesTableProps.cryptoWithdrawSearchValue,
    setCryptoWithdrawSearchValue:
      landingFeesTableProps.setCryptoWithdrawSearchValue,
    fullyLoaded: landingFeesTableProps.fullyLoaded,
    setFullyLoaded: landingFeesTableProps.setFullyLoaded,
  };
};
