import styled, { css } from 'styled-components';
import { NetworksType } from '@libs/types';
import { EthUsdtIcon, TronUsdtIcon, BscUsdtIcon } from '../assets';

// Restyled ICONS
const defaultSizeStyles = css<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const BSCUsdtIcon = styled(BscUsdtIcon)`
  ${defaultSizeStyles}
`;
const ETHUsdtIcon = styled(EthUsdtIcon)`
  ${defaultSizeStyles}
`;
const TRONUsdtIcon = styled(TronUsdtIcon)`
  ${defaultSizeStyles}
`;

export type NetworkIcons = Record<NetworksType, React.ReactElement>;
export const useNetworkIcons = (size = '4rem'): NetworkIcons => ({
  [NetworksType.USDT_BSC]: <BSCUsdtIcon size={size} />,
  [NetworksType.USDT_ERC20]: <ETHUsdtIcon size={size} />,
  [NetworksType.TRX_USDT_S2UZ]: <TRONUsdtIcon size={size} />,
});
export default useNetworkIcons;
