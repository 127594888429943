import styled, { css } from 'styled-components';

import { AppFontKeys } from '@libs/theme';

export const TYPOGRAPHY_STYLES = {
  primary: css`
    color: ${({ theme }) => theme.colors.text.default};
  `,
  secondary: css`
    color: ${({ theme }) => theme.colors.text.secondary};
  `,
  largeTitle: css`
    font-size: 2.8rem;
    line-height: 3.4rem;

    ${({ theme }) => theme.media.tablet`
      font-size: 3.6rem;
      line-height: 4.4rem;
    `}
  `,
  titleOne: css`
    font-size: 2.8rem;
    line-height: 3.4rem;
  `,
  titleTwo: css`
    font-size: 2.4rem;
    line-height: 3.2rem;
  `,
  headline: css`
    font-size: 2rem;
    line-height: 3rem;
  `,
  subHeadline: css`
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
  `,
  bodyButton: css`
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  caption: css`
    font-size: 1.2rem;
    line-height: 1.8rem;
  `,
};

export type TextType = keyof typeof TYPOGRAPHY_STYLES;
export type TextProps = {
  type?: TextType;
  lineHeight?: number;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  fontFamily?: AppFontKeys;
};
export const Text = styled.span<TextProps>`
  line-height: ${({ lineHeight }) =>
    lineHeight ? `${lineHeight}rem !important` : 'inherit'};

  font-size: ${({ fontSize }) =>
    fontSize ? `${fontSize}rem !important` : 'inherit'};
  ${({ type }) => type && TYPOGRAPHY_STYLES[type]}

  ${({ color, type }) => {
    let colorToAssing = '';

    if (color) colorToAssing = color;
    else if (!type) colorToAssing = 'inherit';

    return colorToAssing
      ? css`
          color: ${colorToAssing};
        `
      : '';
  }};

  font-family: ${({ fontFamily, type, theme }) => {
    if (fontFamily) return theme.fonts[fontFamily];
    if (!type) return 'inherit';
    return theme.fonts.primary;
  }};
`;
