import Svg from '../BaseIcon';

export const CredoBankIcon = () => {
  return (
    <Svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_898_2080)">
        <circle cx="24" cy="20" r="20" fill="#F45A38" />
        <path
          d="M27.1418 25.914C23.0338 28.0546 18.38 25.6743 17.4355 21.5672C17.1448 20.3032 17.2251 19.0403 17.6284 17.8021C17.7563 17.4096 17.9715 17.3058 18.4014 17.3958C19.4689 17.6192 20.2848 16.969 20.3802 15.8909C20.4265 15.367 20.2898 14.5506 20.5623 14.3761C21.2989 13.9043 22.1888 13.5382 23.056 13.4264C27.8709 12.8052 31.617 17.0699 30.4539 21.7796C30.3169 22.3341 30.2196 22.8125 29.357 22.5764C28.4664 22.3325 27.6862 23.0908 27.6398 24.0252C27.6217 24.3915 27.6899 24.7712 27.6151 25.1228C27.5572 25.3948 27.3379 25.6324 27.1418 25.914Z"
          fill="white"
        />
        <path
          d="M29.9855 14.3517C27.2158 11.6298 24.0467 11.0929 20.3882 12.4935C20.3882 11.9115 20.3469 11.4079 20.4115 10.9184C20.433 10.756 20.7048 10.5553 20.8993 10.4976C24.3814 9.46545 27.5816 10.0206 30.4028 12.3351C34.4841 15.6831 35.1814 21.6724 32.038 26.0024C31.9386 26.1393 31.8393 26.2762 31.6778 26.4989C32.0795 26.7247 32.4747 26.9049 32.8177 27.1563C33.0056 27.2941 33.1053 27.552 33.2447 27.7557C33.0158 27.8994 32.7915 28.1574 32.557 28.1671C31.4818 28.2116 30.4038 28.188 29.3268 28.186C28.8791 28.1852 28.6476 27.9588 28.6469 27.5082C28.6454 26.4312 28.6253 25.3535 28.6633 24.2779C28.6708 24.066 28.8991 23.6978 29.038 23.6918C29.2647 23.6821 29.5692 23.8584 29.719 24.051C29.9631 24.3651 30.109 24.7555 30.3113 25.1421C32.6488 22.7769 33.1722 17.7042 29.9855 14.3517Z"
          fill="white"
        />
        <path
          d="M17.8381 11.829C18.1042 11.8293 18.3006 11.8404 18.4956 11.8278C19.0847 11.7898 19.3494 12.0566 19.3403 12.6489C19.3256 13.6031 19.3678 14.5594 19.3179 15.5112C19.3027 15.8 19.0631 16.0771 18.9262 16.3596C18.6807 16.1734 18.3953 16.0211 18.2003 15.7919C17.9861 15.54 17.855 15.2173 17.683 14.9172C16.1306 16.1897 14.8701 20.066 16.4479 23.4077C17.4287 25.4848 18.9872 26.9692 21.1569 27.7767C23.3005 28.5745 25.4184 28.391 27.6349 27.4778C27.6349 28.0879 27.6863 28.597 27.609 29.0857C27.5791 29.2747 27.2836 29.4998 27.0649 29.5695C20.0889 31.7898 13.1874 26.0628 14.0778 18.7867C14.2762 17.1653 14.8467 15.6625 15.7889 14.3136C15.9549 14.0761 16.1176 13.8363 16.3377 13.5161C15.9135 13.2881 15.5096 13.1257 15.1761 12.8711C14.9805 12.7217 14.8969 12.4255 14.7629 12.1953C14.9814 12.0748 15.1947 11.863 15.4193 11.8503C16.2 11.8059 16.9846 11.8306 17.8381 11.829Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_898_2080"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_898_2080"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_898_2080"
            result="shape"
          />
        </filter>
      </defs>
    </Svg>
  );
};

export default CredoBankIcon;
