import Svg from '../BaseIcon';

export const SilkBankIcon = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FEC341" />
      <path
        d="M10 16.7812L14.5208 23.4167H20.0625L15.5417 16.7812L18.8958 11.75H25.3854V10H14.5208L10 16.7812Z"
        fill="white"
      />
      <path
        d="M31 23.3438L26.4792 16.7083L20.9375 16.7083L25.4583 23.3438L22.1042 28.375L15.6146 28.375L15.6146 30.125L26.4792 30.125L31 23.3438Z"
        fill="white"
      />
    </Svg>
  );
};

export default SilkBankIcon;
