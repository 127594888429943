import Svg from '../BaseIcon';

export const LibertyBankIcon = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F3000B" />
      <g clipPath="url(#clip0_894_1890)">
        <path
          d="M17.3348 31.2499V26.679C18.3513 26.679 19.2923 26.6953 20.2327 26.6756C21.8022 26.6426 22.6695 25.869 22.6397 24.5595C22.6104 23.2741 21.8027 22.601 20.2415 22.5809C19.3285 22.5692 18.4156 22.5788 17.4076 22.5788V18.3499C18.1273 18.3499 18.8427 18.3577 19.5577 18.3483C21.0474 18.329 21.8868 17.6301 21.8806 16.4246C21.8744 15.219 21.0539 14.5364 19.5356 14.5238C17.6295 14.5071 15.7228 14.5199 13.7501 14.5199V10.0523C16.7686 10.2339 19.8479 9.54849 22.7998 10.6118C26.7871 12.0477 27.9511 16.4974 25.2902 19.7695C25.1749 19.9129 25.0739 20.0693 24.9501 20.2424C27.2893 21.9573 28.0048 24.2343 27.1567 26.8663C26.297 29.5339 24.1803 30.7541 21.4438 31.0573C21.1448 31.1055 20.8486 31.1701 20.5567 31.2506L17.3348 31.2499Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_894_1890">
          <rect
            width="13.75"
            height="21.25"
            fill="white"
            transform="translate(13.75 10)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default LibertyBankIcon;
