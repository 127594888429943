import styled, { css } from 'styled-components';

import Typography from '../typography/Typography';

type TitleProps = {
  hasDesc: boolean;
};

type TextWrapperProps = {
  justifyToLeft: boolean;
};

type HeadType = {
  headerColor?: string;
  headerHeight?: number;
  borderRadius?: string;
  hasStickyHeader?: boolean;
  stickTo?: number;
};

type BodyType = {
  bodyHeight?: number;
  hasButtons?: boolean;
  bodyColor?: string;
};
type CustomTypographyProps = {
  isAsset: boolean;
};
type CellDescriptionProps = {
  hasSecondaryDesc?: boolean;
};
export const TableContainer = styled.table`
  width: 100%;

  white-space: nowrap;
`;

export const Tr = styled.tr`
  width: 100%;
`;

export const CustomTHead = styled.thead<HeadType>`
  &.pinned {
    tr {
      z-index: ${({ theme }) => theme.zIndex.high};
    }
    tr:first-child {
      position: sticky;
      top: ${({ stickTo }) => stickTo}rem;

      th {
        &:first-child {
          border-top-left-radius: 0;
        }
        &:last-child {
          border-top-right-radius: 0;
        }
      }
    }
  }

  ${Tr} {
    height: ${({ headerHeight = 3.1 }) => headerHeight}rem;

    background-color: ${({ headerColor }) => headerColor || 'initial'};

    th:first-child {
      border-top-left-radius: ${({ borderRadius }) => borderRadius || 0};
    }
    th:last-child {
      border-top-right-radius: ${({ borderRadius }) => borderRadius || 0};
    }
  }
`;

type LineProps = {
  stickTo?: number;
  headerHeight?: number;
  hasStickyHeader?: boolean;
};

export const Line = styled.tr<LineProps>`
  position: ${({ hasStickyHeader }) =>
    hasStickyHeader ? 'sticky' : 'relative'};
  top: ${({ headerHeight = 3.1, stickTo = 0, hasStickyHeader }) =>
    hasStickyHeader ? headerHeight + stickTo : 0}rem;
  left: 0;

  height: 1px;
  width: 100%;

  th {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;

    background-color: ${({ theme }) => theme.colors.borders.default};
  }
`;

export const CustomTBody = styled.tbody<BodyType>`
  width: 100%;
  ${({ bodyColor }) => bodyColor && `background-color: ${bodyColor}`};
  ${Tr} {
    cursor: ${({ hasButtons }) => (hasButtons ? 'pointer' : 'default')};
    height: ${({ bodyHeight = 7.3 }) => bodyHeight}rem;

    &:not(:last-child) {
      border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
    }
  }
`;

export const Th = styled.th`
  font-weight: unset;

  vertical-align: middle;

  padding: 0.1rem 0.2rem 0;

  text-align: left;

  ${({ theme }) => theme.media.mobileL`
    padding:0;
    padding-right: 1rem;
  `};

  ${({ theme }) => theme.media.laptop`
    padding: 0;
    padding-right: 2.4rem;
  `};

  &:first-child {
    ${({ theme }) => theme.media.mobileL`
      padding-left:1.6rem;
    `}
    ${({ theme }) => theme.media.laptop`
      padding-left: 2.4rem;
  `};
  }

  &:last-child {
    text-align: right;
    ${({ theme }) => theme.media.mobileL`
      text-align: left;
    `}
  }
`;

export const Td = styled.td`
  position: relative;
  vertical-align: middle;

  width: 1%;

  padding: 0 0.2rem;

  ${({ theme }) => theme.media.mobileL`
    padding: 0;
    padding-right:1rem;
  `};

  ${({ theme }) => theme.media.laptop`
    padding:0;
    padding-right:2.4rem;
  `};

  &:first-child {
    padding: 0 0.5rem;

    ${({ theme }) => theme.media.mobileL`
    padding-left: 1.6rem;
  `};

    ${({ theme }) => theme.media.laptop`
    padding-left: 2.4rem;
  `};
  }

  &:last-child {
    text-align: right;
    ${({ theme }) => theme.media.mobileL`
      text-align: left;
    `}
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;

  max-height: 3.8rem;
  margin-right: 1rem;
  ${({ theme }) => theme.media.tablet`
    margin-bottom: 0;
  `}
`;

export const Img = styled.img<{ imgSize?: number }>`
  max-height: ${({ imgSize }) => (imgSize ? imgSize : 3.8)}rem;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ justifyToLeft }) => (justifyToLeft ? 'start' : 'end')};

  width: 100%;
`;

export const CellTitle = styled.div<TitleProps>`
  display: flex;

  ${({ hasDesc }) =>
    hasDesc &&
    `
      margin-bottom:0.1rem;  
  `}
`;

export const CustomTypography = styled(Typography)<CustomTypographyProps>`
  ${({ isAsset, theme }) =>
    isAsset &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70px;

      ${theme.media.mobileL`
      white-space: nowrap;
      width: unset;
      overflow: auto;
    `}
    `}
`;

export const CellDescription = styled.div<CellDescriptionProps>`
  display: flex;
  flex-direction: column;
  ${({ hasSecondaryDesc }) => hasSecondaryDesc && 'gap: 0.2rem'};
`;

type StatusWrapperType = {
  backColor?: string;
};

export const StatusWrapper = styled.div<StatusWrapperType>`
  padding: 0.2rem 0.8rem 0.4rem;

  width: fit-content;

  border-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ backColor, theme }) =>
    backColor ? backColor : theme.colors.surface.interactiveDefault};
`;
