import Svg from '../BaseIcon';

export const TeraBankIcon = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#AD006D" />
      <path
        d="M28.3432 13.2225C29.0389 13.4336 29.6209 13.816 30.1135 14.3315C30.9104 15.1654 31.2126 16.1799 31.2464 17.2878C31.2927 18.8063 30.8638 20.228 30.274 21.6109C29.1514 24.2425 27.4756 26.4845 25.1976 28.29C24.669 28.7059 24.1136 29.0886 23.5347 29.4357C22.6839 29.9519 21.7579 30.3411 20.788 30.5902C20.155 30.7535 19.5063 30.8203 18.8654 30.7263C18.1549 30.6222 17.4607 30.4336 16.8177 30.0925C15.6697 29.4837 14.8314 28.5818 14.162 27.5142C13.1586 25.9137 12.5798 24.155 12.1229 22.3512C11.9465 21.6551 11.7998 20.9527 11.6609 20.2488C11.6052 19.9879 11.6213 19.7125 11.4959 19.4653C11.4865 19.0312 11.4156 18.6025 11.3733 18.1714C11.2601 16.9895 11.2379 15.8011 11.3069 14.616C11.3657 13.5615 11.492 12.5141 11.6434 11.4691C11.6489 11.418 11.6589 11.3675 11.6733 11.318C11.6957 11.2511 11.7304 11.1875 11.8186 11.1932C11.9018 11.1986 11.9416 11.2504 11.9514 11.3279C11.983 11.5768 12.0169 11.8255 12.0469 12.0746C12.28 14.0077 12.6107 15.9227 13.2331 17.779C13.3936 18.2577 13.577 18.7315 13.8442 19.1681C13.8654 19.203 13.8661 19.2603 13.9288 19.2618L13.9254 19.2591C13.9126 19.3421 13.9433 19.4158 13.9807 19.4868C14.3708 20.2278 14.769 20.9644 15.241 21.661C16.0679 22.8815 17.0286 23.9811 18.272 24.8264C20.0118 26.0093 21.9099 26.3724 23.9491 25.7609C25.6947 25.2374 27.0312 24.1618 28.028 22.6844C28.2425 22.3664 28.4511 22.0446 28.6625 21.7245C28.7707 21.7792 28.7734 21.668 28.7979 21.6284C29.4235 20.6226 29.9203 19.5624 30.1707 18.4077C30.3483 17.5885 30.4013 16.7648 30.1831 15.9396C29.9052 14.8882 29.4255 13.9671 28.458 13.3486C28.406 13.3206 28.3654 13.276 28.3432 13.2225Z"
        fill="white"
      />
      <path
        d="M15.6517 16.988C15.6287 16.912 15.5668 16.8677 15.5088 16.8189C14.1354 15.6663 13.3631 14.2023 13.1117 12.4682C13.0644 12.1347 13.0352 11.7991 13.0242 11.4626C13.0171 11.2661 13.0478 11.2438 13.2469 11.295C14.8638 11.7088 16.403 12.3684 17.8074 13.2494C19.1691 14.1019 20.3944 15.1439 21.4444 16.3421C21.7594 16.7004 21.9819 17.1176 22.2986 17.4706C22.3206 17.495 22.3414 17.5205 22.3616 17.5443C22.4272 17.5031 22.3909 17.4682 22.3714 17.4372C20.863 15.0382 18.8724 13.1308 16.2875 11.8302C15.3785 11.3746 14.4251 11.0076 13.4414 10.7346C13.3942 10.7214 13.3216 10.7389 13.3141 10.669C13.3053 10.5871 13.3886 10.5908 13.4379 10.5736C14.102 10.3412 14.7712 10.1287 15.4829 10.0786C15.669 10.0655 15.8443 9.99555 16.038 10.0002C17.3559 10.0319 18.556 10.3951 19.6192 11.1626C20.6128 11.8799 21.3722 12.7952 22.0134 13.8116C22.056 13.8791 22.0883 13.9554 22.1679 13.9935C22.1939 14.1798 22.3168 14.3248 22.3924 14.4899C22.7289 15.2247 23.0642 15.9599 23.3985 16.6955C23.4219 16.7468 23.438 16.8048 23.5016 16.8269C23.5193 16.8869 23.5347 16.9476 23.5553 17.0066C23.7978 17.7022 24.0163 18.4047 24.1971 19.1179C24.2522 19.3352 24.2238 19.3711 23.9973 19.3698C22.4978 19.3663 21.0049 19.1766 19.555 18.8055C18.8379 18.6201 18.1563 18.3429 17.4687 18.0802C17.4258 18.0255 17.3686 17.9828 17.3031 17.9566C16.7932 17.7092 16.3092 17.4143 15.858 17.076C15.8012 17.0253 15.7286 16.9943 15.6517 16.988Z"
        fill="#5FBF0E"
      />
      <path
        d="M23.4986 16.8266C23.435 16.8045 23.4189 16.7465 23.3955 16.6952C23.0601 15.96 22.7247 15.2248 22.3894 14.4896C22.3138 14.3245 22.1909 14.1795 22.1649 13.9932C23.2601 13.6731 24.3629 13.3827 25.4957 13.2149C26.3052 13.095 27.1171 13.017 27.9358 13.1271C28.0749 13.1423 28.2115 13.1742 28.3424 13.2222C28.3647 13.2757 28.4053 13.3202 28.4574 13.3482C29.4248 13.9667 29.9045 14.8878 30.1825 15.9391C30.4006 16.7644 30.3476 17.5881 30.17 18.4073C29.9196 19.5619 29.4228 20.6219 28.7972 21.628C28.7727 21.6675 28.77 21.7787 28.6617 21.7241C28.7548 21.5034 28.8321 21.2767 28.8931 21.0456C29.1512 20.0822 29.0982 19.1569 28.5047 18.3107C28.1244 17.7685 27.5664 17.442 26.9539 17.2033C25.8656 16.7792 24.7227 16.7484 23.5722 16.7895C23.5575 16.7884 23.5429 16.7913 23.5299 16.7979C23.5169 16.8044 23.5061 16.8144 23.4986 16.8266Z"
        fill="#4E1831"
      />
      <path
        d="M15.6498 16.9875C15.7266 16.9938 15.7992 17.0248 15.856 17.0754C16.3072 17.4137 16.7912 17.7086 17.3011 17.956C17.3666 17.9822 17.4237 18.0249 17.4666 18.0797C16.3624 18.4493 15.2658 18.8391 14.1769 19.2492C14.0932 19.2806 14.0121 19.3201 13.9258 19.2584L13.9292 19.261C13.9595 19.1852 13.8934 19.14 13.8647 19.0859C13.7338 18.8387 13.5988 18.5945 13.5266 18.3215C13.5026 18.2308 13.524 18.1903 13.5999 18.1446C14.272 17.7398 14.9581 17.3587 15.6498 16.9875Z"
        fill="#4E1831"
      />
      <path
        d="M11.4973 19.4649C11.6227 19.7121 11.6066 19.9874 11.6623 20.2484C11.3341 20.3383 11.0234 20.4729 10.7151 20.6102C10.1376 20.8638 9.53645 21.0632 8.91989 21.2059C8.88012 21.2119 8.83987 21.2143 8.79965 21.2132L8.80319 21.2167C9.08035 20.9905 9.3996 20.8245 9.69858 20.6295C10.297 20.2395 10.8966 19.8513 11.4973 19.4649Z"
        fill="#4E1831"
      />
      <path
        d="M8.79859 21.2134C8.80686 21.2465 8.78292 21.242 8.76323 21.2412C8.7587 21.241 8.75438 21.236 8.75 21.2331L8.80205 21.2168L8.79859 21.2134Z"
        fill="#4E1831"
      />
    </Svg>
  );
};

export default TeraBankIcon;
