import Svg, { SvgIconDefaultProps } from '../BaseIcon';

export const BasisBankIcon: React.FC<SvgIconDefaultProps> = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#15448A" />
      <g clipPath="url(#clip0_894_1889)">
        <path
          d="M22.1615 18.8213C22.6409 19.0748 23.1555 19.3001 23.6211 19.5998C24.5539 20.1523 25.2362 21.0345 25.5283 22.0656C25.6892 22.6453 25.6982 23.2557 25.5545 23.8398C25.4107 24.4239 25.1188 24.9627 24.7061 25.4061C23.817 26.4039 22.6117 27.0781 21.2848 27.3199C20.6322 27.4498 19.9669 27.5081 19.3012 27.4938C16.7042 27.4922 14.1073 27.4922 11.5105 27.4938C11.2503 27.4938 11.25 27.4938 11.25 27.2375C11.25 21.9953 11.25 16.7531 11.25 11.511C11.25 11.2635 11.25 11.2632 11.497 11.2632C14.3259 11.2632 17.1555 11.2315 19.9842 11.2749C20.7484 11.2668 21.5058 11.4165 22.2074 11.7142C22.9091 12.012 23.5393 12.4511 24.0573 13.0033C24.6562 13.6102 24.9917 14.4218 24.9933 15.2671C24.9949 16.1124 24.6623 16.9253 24.0657 17.5343C23.5382 18.0993 22.8871 18.5394 22.1615 18.8213ZM16.3356 23.5462C16.9305 23.5462 17.5044 23.5649 18.0767 23.5399C18.4893 23.5212 18.8834 23.3661 19.1952 23.0998C19.3311 22.9964 19.4415 22.8642 19.518 22.713C19.5946 22.5618 19.6354 22.3955 19.6374 22.2267C19.6394 22.0578 19.6025 21.8907 19.5295 21.7378C19.4565 21.5849 19.3493 21.4502 19.2158 21.3438C18.9879 21.1421 18.7099 21.0033 18.4098 20.9414C17.7324 20.8564 17.0482 20.8366 16.3669 20.8821C16.3578 20.8821 16.3488 20.8967 16.3372 20.9065L16.3356 23.5462ZM16.3214 17.4589C16.7967 17.4589 17.2494 17.4772 17.6995 17.4532C18.0817 17.4314 18.4452 17.2835 18.7312 17.0333C18.855 16.9389 18.9557 16.8185 19.0261 16.681C19.0965 16.5434 19.1348 16.3921 19.1381 16.2382C19.1414 16.0843 19.1097 15.9315 19.0452 15.7912C18.9808 15.6508 18.8853 15.5264 18.7657 15.4269C18.5581 15.2521 18.3169 15.1201 18.0564 15.0384C17.4944 14.8613 16.9085 14.9868 16.3227 14.9478L16.3214 17.4589Z"
          fill="white"
        />
        <path
          d="M27.7119 18.8581C28.3232 19.0653 28.8992 19.3617 29.4208 19.7373C30.2584 20.3064 30.8644 21.1481 31.1316 22.1137C31.2771 22.6657 31.2846 23.2442 31.1534 23.7997C31.0222 24.3552 30.7562 24.8712 30.378 25.3038C29.4956 26.3658 28.2518 27.078 26.8765 27.3088C26.289 27.4147 25.6936 27.4734 25.0964 27.4843C24.2971 27.5135 23.4959 27.4926 22.6953 27.4932H22.5202L22.5067 27.4447C22.5621 27.4203 22.6166 27.394 22.6731 27.3722C23.7823 26.9748 24.7506 26.2699 25.4604 25.3427C25.995 24.6473 26.2819 23.7983 26.2771 22.9268C26.2608 21.9017 25.8573 20.9192 25.1451 20.1702C24.6917 19.6723 24.155 19.2543 23.5581 18.9344C23.5104 18.9083 23.4634 18.8808 23.4172 18.8521C23.4085 18.8467 23.405 18.8324 23.394 18.8131C23.571 18.6889 23.7535 18.5701 23.9257 18.4379C24.7741 17.84 25.364 16.9517 25.5794 15.9478C25.6827 15.4241 25.6651 14.8843 25.5278 14.3682C25.3905 13.8522 25.1371 13.3729 24.7862 12.9659C24.2145 12.272 23.4752 11.7296 22.6357 11.388C22.5912 11.3687 22.5467 11.3494 22.5031 11.3284C22.4932 11.3237 22.487 11.3117 22.4651 11.2873C22.5042 11.2751 22.5445 11.2667 22.5854 11.2622C23.5997 11.2622 24.6166 11.2305 25.6278 11.2701C26.9723 11.2938 28.2658 11.7803 29.2828 12.645C29.6754 12.9543 29.995 13.3437 30.2196 13.7862C30.4441 14.2288 30.5683 14.714 30.5834 15.2083C30.588 15.7149 30.477 16.216 30.2584 16.6748C30.0398 17.1336 29.7193 17.5384 29.3205 17.8594C29.0822 18.0654 28.8266 18.2512 28.5563 18.4148C28.2923 18.5774 28.0098 18.7041 27.7119 18.8581Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_894_1889">
          <rect
            width="20"
            height="16.25"
            fill="white"
            transform="translate(11.25 11.25)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default BasisBankIcon;
