import CustomSvg, { SvgIconDefaultProps } from '../BaseIcon';

const EthUsdtIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <CustomSvg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
    >
      <path
        d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
        fill="#627EEA"
      />
      <path
        d="M15.9824 3.875V12.4678L23.2451 15.7131L15.9824 3.875Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path
        d="M15.9824 3.875L8.71875 15.7131L15.9824 12.4678V3.875Z"
        fill="white"
      />
      <path
        d="M15.9824 21.2824V27.1211L23.25 17.0664L15.9824 21.2824Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path
        d="M15.9824 27.1211V21.2814L8.71875 17.0664L15.9824 27.1211Z"
        fill="white"
      />
      <path
        d="M15.9824 19.931L23.2451 15.7141L15.9824 12.4707V19.931Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M8.71875 15.7141L15.9824 19.931V12.4707L8.71875 15.7141Z"
        fill="white"
        fillOpacity="0.602"
      />
    </CustomSvg>
  );
};

export default EthUsdtIcon;
