import Svg from '../BaseIcon';

export const ZenIcon = () => {
  return (
    <Svg width="40" height="40" viewBox="0 0 200 200" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.43 58.0486C182.447 45.7479 166.529 41.3556 153.368 47.8214C129.042 60.2012 134.519 96.6462 161.334 101.397C160.26 135.286 131.917 161.89 98.0277 160.816C31.6081 157.847 15.7478 67.3234 78.1019 43.1264C82.675 41.4715 85.311 36.6809 84.2616 31.931C82.7409 26.2292 86.8855 20.3642 92.7697 19.9072C98.1262 19.4162 103.563 19.4784 108.915 20.0666C114.466 20.6925 119.449 16.458 119.738 10.883C119.881 5.56092 115.847 1.05208 110.542 0.604337C51.9902 -5.90666 -0.368073 41.052 0.0019495 100.026C0.0015658 155.241 44.7618 200 99.9763 200C155.192 200 199.95 155.241 199.95 100.026C199.95 90.2505 197.985 69.1799 190.43 58.0486Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.684 7.64111C143.404 7.64111 148.041 12.278 148.041 17.9978C147.496 31.7357 127.87 31.7318 127.327 17.9977C127.327 12.278 131.964 7.64111 137.684 7.64111Z"
        fill="black"
      />
    </Svg>
  );
};

export default ZenIcon;
