import Svg from '../BaseIcon';

export const PashBankIcon = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#002882" />
      <g clipPath="url(#clip0_898_2495)">
        <path
          d="M19.9403 31C19.863 30.9002 19.78 30.805 19.6917 30.7147C18.2993 29.3252 16.9069 27.9358 15.5468 26.5836L20.0597 22.0804C20.3257 22.3533 20.6365 22.656 20.92 22.9786C20.9858 23.0837 21.0154 23.2074 21.0045 23.3309C21.0045 24.8196 21.0045 26.3281 21.0045 27.8291V28.2311H22.745C21.7902 29.1962 20.8976 30.0969 20.0099 31H19.9403Z"
          fill="#DC1834"
        />
        <path
          d="M28.3445 18.9244V17.3068L31 19.9541L26.6139 24.3283L22.1906 19.8871C22.4591 19.6142 22.7674 19.2842 23.0981 18.979C23.1727 18.9244 23.3169 18.9244 23.4213 18.9244C24.9132 18.9244 26.4051 18.9244 27.8969 18.9244H28.3445Z"
          fill="#DC1834"
        />
        <path
          d="M13.3712 15.5228L17.8467 19.9888C17.5533 20.2692 17.25 20.5769 16.9243 20.8572C16.8165 20.9187 16.6923 20.9456 16.5687 20.9341H11.663V22.5791L9 19.8921L13.3712 15.5228Z"
          fill="#DC1834"
        />
        <path
          d="M14.7586 26.6159L16.0018 27.7274C15.8601 27.7423 15.8104 27.7522 15.7532 27.7522C14.6417 27.7522 13.5303 27.7522 12.4164 27.7522C12.2274 27.7522 12.1255 27.72 12.1255 27.5041C12.1255 25.5639 12.1255 23.6212 12.1255 21.681C12.1307 21.6143 12.1398 21.548 12.1528 21.4825H18.4708C18.4708 21.9266 18.4708 22.3558 18.4708 22.7826C18.4708 22.8793 18.3639 22.9811 18.2868 23.058C17.2177 24.1067 16.1502 25.1595 15.0843 26.2164C14.9867 26.2992 14.8818 26.3731 14.771 26.4372L14.7586 26.6159Z"
          fill="#008455"
        />
        <path
          d="M21.5366 21.4452C21.9767 21.4452 22.3895 21.4304 22.7997 21.4452C22.9308 21.4749 23.0486 21.5463 23.1354 21.6487C24.2112 22.7106 25.2837 23.7775 26.3529 24.8493C26.4226 24.928 26.4873 25.0108 26.5468 25.0974L27.698 23.8991L27.7975 23.9636C27.8121 24.0256 27.822 24.0886 27.8273 24.1521C27.8273 25.2413 27.8273 26.3281 27.8273 27.4148C27.8273 27.6406 27.7776 27.7324 27.5289 27.7299C25.6218 27.7299 23.7123 27.7299 21.8101 27.7299C21.6062 27.7299 21.4993 27.6927 21.4993 27.457C21.4993 25.5242 21.4993 23.5914 21.4993 21.6611C21.5068 21.614 21.5242 21.5519 21.5366 21.4452Z"
          fill="#008455"
        />
        <path
          d="M20.0398 9L24.4159 13.3444L19.9403 17.7955C19.6519 17.4978 19.3411 17.1976 19.0601 16.875C18.9998 16.767 18.9738 16.6433 18.9855 16.5202C18.9855 15.02 18.9855 13.5206 18.9855 12.022V11.625H17.4041L20.0398 9Z"
          fill="#DC1834"
        />
        <path
          d="M13.3935 14.6619L12.1652 15.9769V12.1535H18.4485V18.4084C18.0108 18.4084 17.5608 18.4084 17.1132 18.4084C17.0212 18.4084 16.9268 18.2794 16.8472 18.2C15.7846 17.1414 14.7271 16.0852 13.6745 15.0316C13.5875 14.9547 13.5228 14.8306 13.3935 14.6619Z"
          fill="#008455"
        />
        <path
          d="M24.0902 12.1485L24.033 12.223L25.219 13.3047C25.1022 13.4288 25.04 13.5007 24.9704 13.5677C24.0529 14.4857 23.1727 15.4509 22.1955 16.3094C21.8462 16.6128 21.5504 16.9726 21.3203 17.3737L20.4699 18.4282H21.8151H21.8822C23.7371 18.4282 25.592 18.4282 27.4494 18.4282C27.5637 18.4282 27.6756 18.4282 27.8074 18.4109V12.1485H24.0902Z"
          fill="#008455"
        />
      </g>
      <defs>
        <clipPath id="clip0_898_2495">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default PashBankIcon;
