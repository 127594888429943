import CustomSvg, { SvgIconDefaultProps } from '../BaseIcon';

const TronUsdtIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <CustomSvg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
    >
      <path
        d="M15.5 31C24.0636 31 31 24.0588 31 15.5C31 6.94122 24.0636 0 15.5 0C6.9364 0 0 6.94122 0 15.5C0 24.0588 6.94234 31 15.5 31Z"
        fill="#FF060A"
      />
      <path
        d="M24.7414 12.4246C23.8556 11.6436 22.6252 10.4537 21.6287 9.61172L21.5671 9.57511C21.4687 9.50189 21.358 9.44087 21.2411 9.39816C18.8296 8.97105 7.60901 6.97582 7.39371 7.00022C7.33219 7.00632 7.27067 7.03073 7.22146 7.06124L7.16609 7.10395C7.09843 7.17107 7.04306 7.25039 7.0123 7.34191L7 7.37852V7.57988V7.61038C8.26109 10.9541 13.2501 21.9004 14.2344 24.4753C14.2959 24.6522 14.4066 24.9817 14.6158 25H14.665C14.7757 25 15.2555 24.3959 15.2555 24.3959C15.2555 24.3959 23.8248 14.5235 24.6922 13.474C24.8029 13.3459 24.9013 13.2056 24.9875 13.0591C25.0121 12.9432 24.9998 12.8273 24.9567 12.7174C24.9136 12.6076 24.8337 12.5039 24.7414 12.4246ZM17.4455 13.5778L21.0996 10.6978L23.2465 12.5771L17.4455 13.5778ZM16.0245 13.3886L9.73134 8.48292L19.9185 10.2707L16.0245 13.3886ZM16.5904 14.67L23.0312 13.6815L15.6677 22.12L16.5904 14.67ZM8.87626 8.97715L15.5016 14.3161L14.5419 22.1261L8.87626 8.97715Z"
        fill="white"
      />
    </CustomSvg>
  );
};

export default TronUsdtIcon;
