import CustomSvg, { SvgIconDefaultProps } from '../BaseIcon';

const BscUsdtIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <CustomSvg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
    >
      <path
        d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
        fill="#0B0E11"
      />
      <path
        d="M11.1522 13.7126L15.5002 9.36476L19.8504 13.7149L22.3803 11.1849L15.5002 4.30469L8.62222 11.1827L11.1522 13.7126ZM6.83572 12.969L9.36567 15.4989L6.83559 18.029L4.30566 15.499L6.83572 12.969ZM11.1522 17.2856L15.5002 21.6333L19.8503 17.2834L22.3816 19.8121L22.3803 19.8134L15.5002 26.6936L8.62212 19.8156L8.6186 19.8121L11.1522 17.2856ZM26.6948 15.4994L24.1648 18.0293L21.6349 15.4994L24.1648 12.9694L26.6948 15.4994Z"
        fill="#F0B90B"
      />
      <path
        d="M18.0663 15.499H18.0675L15.5002 12.9316L12.9316 15.5002L12.9351 15.5038L15.5002 18.0688L18.0687 15.5002L18.0663 15.499Z"
        fill="#F0B90B"
      />
    </CustomSvg>
  );
};

export default BscUsdtIcon;
