export enum InfoPaths {
  DEPOSIT = 'deposit',
  WITHDRAW_METHODS = 'withdraw-methods',
  WITHDRAW_CRYPTO_FEE = 'withdraw-crypto-fee',
  CONVERT_FEE = 'convert-fee',
}

export enum FeesTablesSelectorId {
  OPTION_LIST = 'option-list',
  TABLE = 'table',
  EXCHANGE_FEES_TABLE = 'exchange-fees-table',
}
