import { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { Text, TextProps, TextType } from './styles';

export * from './styles';

export type TypographyProps = TextProps & {
  children: React.ReactNode;
  lineHeight?: number;
  type?: TextType;
  testId?: string;
  className?: string;
  //should be removed
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  treatAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  ref?:
    | ((instance: HTMLSpanElement | null) => void)
    | React.RefObject<HTMLSpanElement>
    | null
    | undefined;
};

type Ref = HTMLSpanElement;

const Typography = forwardRef<Ref, TypographyProps>(
  (
    {
      type,
      lineHeight,
      color,
      children,
      fontSize,
      className,
      fontFamily,
      treatAs,
      as = 'span',
      testId = 'text',
    },
    ref
  ) => {
    const { colors } = useTheme();
    const colorToApply = color || colors.text.default;

    return (
      <Text
        ref={ref}
        as={treatAs || as}
        type={type}
        color={colorToApply}
        data-cy={testId}
        data-testid={testId}
        lineHeight={lineHeight}
        fontSize={fontSize}
        className={className}
        fontFamily={fontFamily}
      >
        {children}
      </Text>
    );
  }
);

Typography.displayName = 'Typography';

export default Typography;
