import { useTheme } from 'styled-components';

import { FrStatuses, UserStatus } from '@libs/graphql';
import {
  CryptoTransactionStatusEnum,
  LoanHealthStatus,
  LoanStatus,
  SwapStatusEnumKeys,
  TransactionStatusType,
} from '@libs/types';
import {
  FrStatusEnum,
  ManualActionStatusEnum,
  RiskLevelEnum,
  StakingHarvestModes,
  StakingStatuses,
  StatusEnum,
} from '@libs/backend-common';

type StatusType =
  | FrStatuses
  | UserStatus
  | TransactionStatusType
  | LoanHealthStatus
  | LoanStatus
  | StakingStatuses
  | StakingHarvestModes
  | CryptoTransactionStatusEnum
  | SwapStatusEnumKeys
  | RiskLevelEnum
  | ManualActionStatusEnum;

export const useStatusInfo = () => {
  const { colors } = useTheme();

  const pendingColors = [colors.text.pending, colors.background.pending];
  const successColors = [colors.text.success, colors.background.success];
  const defaultColors = [
    colors.text.default,
    colors.surface.interactiveDefault,
  ];
  const criticalColors = [colors.text.critical, colors.background.critical];

  const statusColors = {
    [FrStatuses.UNVERIFIED]: defaultColors,
    [SwapStatusEnumKeys.DRAFT]: defaultColors,
    [StakingHarvestModes.END_OF_DURATION]: defaultColors,
    [StakingHarvestModes.IMMEDIATE]: defaultColors,
    [StakingHarvestModes.PER_ACCRUAL_INTERVAL]: defaultColors,
    [TransactionStatusType.DRAFT]: defaultColors,
    [CryptoTransactionStatusEnum.SUBMITTED]: defaultColors,

    [UserStatus.ACTIVE]: successColors,
    [FrStatuses.VERIFIED]: successColors,
    [LoanHealthStatus.HEALTHY]: successColors,
    [TransactionStatusType.SUCCEEDED]: successColors,
    [LoanStatus.COVERED]: successColors,
    [StakingStatuses.AWAITING_REDEMPTION]: successColors,
    [CryptoTransactionStatusEnum.COMPLETED]: successColors,
    [SwapStatusEnumKeys.DEPOSIT_CONFIRMED]: successColors,
    [SwapStatusEnumKeys.EXCHANGE_COMPLETED]: successColors,
    [SwapStatusEnumKeys.WITHDRAW_COMPLETED]: successColors,
    [ManualActionStatusEnum.ACCEPTED]: successColors,
    [RiskLevelEnum.LOW]: successColors,

    [FrStatuses.TEMPORARY_REJECTED]: criticalColors,
    [LoanHealthStatus.CRITICAL]: criticalColors,
    [FrStatuses.REJECTED]: criticalColors,
    [UserStatus.BLOCKED]: criticalColors,
    [UserStatus.SUSPENDED]: criticalColors,
    [LoanStatus.LIQUIDATED]: criticalColors,
    [StakingStatuses.CLOSED]: criticalColors,
    [StakingStatuses.CLOSED_CONTINUED]: criticalColors,
    [StakingStatuses.CLOSED_EARLY_REDEEMED]: criticalColors,
    [CryptoTransactionStatusEnum.FAILED]: criticalColors,
    [SwapStatusEnumKeys.DEPOSIT_FAILED]: criticalColors,
    [SwapStatusEnumKeys.EXCHANGE_FAILED]: criticalColors,
    [SwapStatusEnumKeys.WITHDRAW_FAILED]: criticalColors,
    [ManualActionStatusEnum.REJECTED]: criticalColors,
    [RiskLevelEnum.HIGH]: criticalColors,

    [FrStatuses.PENDING]: pendingColors,
    [LoanHealthStatus.NEUTRAL]: pendingColors,
    [TransactionStatusType.PROCESSING]: pendingColors,
    [TransactionStatusType.PENDING_MANUAL_REVIEW]: pendingColors,
    [SwapStatusEnumKeys.WITHDRAW_IN_PROGRESS]: pendingColors,
    [SwapStatusEnumKeys.EXCHANGE_IN_PROGRESS]: pendingColors,
    [ManualActionStatusEnum.PENDING]: pendingColors,
    [SwapStatusEnumKeys.DEPOSIT_PENDING]: pendingColors,
    [RiskLevelEnum.MEDIUM]: pendingColors,
  };

  return {
    getStatusData: (status?: StatusType) =>
      status
        ? {
            colors: statusColors[status],
            text: STATUS_LABELS[status],
          }
        : { colors: defaultColors, text: '' },
  };
};

export const STATUS_LABELS = {
  [UserStatus.ACTIVE]: 'status_active',
  [UserStatus.BLOCKED]: 'status_blocked',
  [UserStatus.SUSPENDED]: 'status_suspended',

  [StatusEnum.ACTIVE]: 'status_active',
  [StatusEnum.BLOCKED]: 'status_blocked',
  [StatusEnum.SUSPENDED]: 'status_suspended',

  [FrStatuses.UNVERIFIED]: 'status_unverified',
  [FrStatuses.VERIFIED]: 'status_verified',
  [FrStatuses.TEMPORARY_REJECTED]: 'status_temporary_rejected',
  [FrStatuses.REJECTED]: 'status_rejected',
  [FrStatuses.PENDING]: 'status_pending',
  [FrStatusEnum.UNVERIFIED]: 'status_unverified',
  [FrStatusEnum.VERIFIED]: 'status_verified',
  [FrStatusEnum.TEMPORARY_REJECTED]: 'status_temporary_rejected',
  [FrStatusEnum.REJECTED]: 'status_rejected',
  [FrStatusEnum.PENDING]: 'status_pending',

  [LoanHealthStatus.HEALTHY]: 'status_healthy',
  [LoanHealthStatus.CRITICAL]: 'status_critical',
  [LoanHealthStatus.NEUTRAL]: 'status_neutral',

  [TransactionStatusType.DRAFT]: 'status_draft',
  [TransactionStatusType.SUCCEEDED]: 'status_succeeded',
  [TransactionStatusType.PROCESSING]: 'status_pending',
  [TransactionStatusType.PENDING_MANUAL_REVIEW]: 'status_pending_manual_review',

  [LoanStatus.COVERED]: 'status_covered',
  [LoanStatus.LIQUIDATED]: 'status_liquidated',

  [StakingStatuses.AWAITING_REDEMPTION]: 'status_awaiting_redemption',
  [StakingStatuses.CLOSED]: 'status_closed',
  [StakingStatuses.CLOSED_CONTINUED]: 'status_closed_continued',
  [StakingStatuses.CLOSED_EARLY_REDEEMED]: 'status_closed_early_redeemed',

  [StakingHarvestModes.END_OF_DURATION]: 'status_end_of_duration',
  [StakingHarvestModes.IMMEDIATE]: 'status_immediate',
  [StakingHarvestModes.PER_ACCRUAL_INTERVAL]: 'status_per_accrual_interval',

  [CryptoTransactionStatusEnum.COMPLETED]: 'status_completed',
  [CryptoTransactionStatusEnum.FAILED]: 'status_failed',
  [CryptoTransactionStatusEnum.SUBMITTED]: 'status_submitted',

  [SwapStatusEnumKeys.DEPOSIT_CONFIRMED]: 'status_deposit_confirmed',
  [SwapStatusEnumKeys.DEPOSIT_FAILED]: 'status_deposit_failed',
  [SwapStatusEnumKeys.DEPOSIT_PENDING]: 'status_deposit_pending',
  [SwapStatusEnumKeys.DRAFT]: 'status_draft',
  [SwapStatusEnumKeys.EXCHANGE_COMPLETED]: 'status_exchange_completed',
  [SwapStatusEnumKeys.EXCHANGE_FAILED]: 'status_exchange_failed',
  [SwapStatusEnumKeys.EXCHANGE_IN_PROGRESS]: 'status_exchange_pending',
  [SwapStatusEnumKeys.WITHDRAW_COMPLETED]: 'status_withdraw_completed',
  [SwapStatusEnumKeys.WITHDRAW_FAILED]: 'status_withdraw_failed',
  [SwapStatusEnumKeys.WITHDRAW_IN_PROGRESS]: 'status_withdraw_pending',

  [RiskLevelEnum.LOW]: 'common_low',
  [RiskLevelEnum.MEDIUM]: 'common_medium',
  [RiskLevelEnum.HIGH]: 'common_high',

  [ManualActionStatusEnum.ACCEPTED]: 'common_approved',
  [ManualActionStatusEnum.PENDING]: 'status_pending',
  [ManualActionStatusEnum.REJECTED]: 'status_rejected',
};
